<template>
  <div class="subjectView">
    <v-row>
      <v-col> <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="sub-title">{{ subjectName }}</h2>
      </v-col>
      <v-col class="text-right">
        <v-btn class="main-btn mt-4" @click="print">{{ $t("Print") }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table class="elevation-1 level1 ">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("Name") }}
                </th>

                <th>{{ subjectName }} ({{ headers.subject_mark }})</th>
                <th>
                  <span class="details">
                    {{ $t("Details") }}
                  </span>
                  (
                  <span
                    v-for="(qualifier, index) in headers.qualifiers"
                    :key="index"
                    class="qualifier-title"
                  >
                    <router-link
                      v-if="qualifier.hasExams"
                      :to="
                        `/educationalhistory/qualifiersmarks?period_id=${selectedPeriod}&quarter_id=${selectedQuarter}&grade_id=${selectedGrade}&class_id=${selectedClass}&subject_id=${selectedSubject}&qualifier_id=${qualifier.subjects_qualifier_id}&subjectTitle=${classTitle}&subject_name=${subjectName}&class_name=${className}&qualifier_title=${qualifier.title}`
                      "
                      >{{ qualifier.title }}</router-link
                    >
                    <span v-else class="mb-0">{{ qualifier.title }}</span>
                    <span
                      class="space"
                      v-if="index < headers.qualifiers.length - 1"
                    >
                      -
                    </span>
                  </span>
                  )
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.student_name }}</td>

                <td>
                  <p
                    v-if="
                      headers.passing_mark &&
                        Number(headers.passing_mark) >
                          Number(student.student_mark)
                    "
                    class="faild"
                  >
                    {{ student.mark_display }}
                  </p>
                  <p
                    v-if="
                      headers.passing_mark &&
                        Number(headers.passing_mark) <=
                          Number(student.student_mark)
                    "
                    class="passed"
                  >
                    {{ student.mark_display }}
                  </p>
                  <p v-if="!headers.passing_mark" class="unAssigned">
                    {{ student.mark_display }}
                  </p>
                </td>

                <td v-if="student.qualifiers.length > 0">
                  <span
                    v-for="(qualifier, index) in student.qualifiers"
                    :key="index"
                    class="qualifierMark"
                  >
                    <span> {{ qualifier.qualifier_title }} </span>
                    <span>{{ qualifier.mark_display }}</span>
                  </span>
                </td>
                <td v-else>N/A</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "subjectView",
  data() {
    return {
      subjectName: "",
      subjectMark: "",
      students: [],
      headers: [],
      selectedPeriod: "",
      selectedQuarter: "",
      selectedGrade: "",
      selectedClass: "",
      selectedSubject: "",
      pdfUrl: "",
      breadCrumb: [],
      classTitle: "",
      className: "",
    };
  },
  methods: {
    getResults() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/admin/overview/subject/show?academic_period_id=" +
            this.selectedPeriod +
            "&grade_id=" +
            this.selectedGrade +
            "&class_id=" +
            this.selectedClass +
            "&subject_id=" +
            this.selectedSubject +
            "&quarter_id=" +
            this.selectedQuarter,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.subjectName = response.data.data.headers.subject_mark;
          this.subjectName = response.data.data.headers.subject_name;
          this.headers = response.data.data.headers;
          this.students = response.data.data.students;

          this.classTitle =
            this.$route.query.class_name + " " + this.subjectName;
          this.className = this.$route.query.class_name;
          let href =
            "/educationalhistory/adminView?period_id=" +
            this.selectedPeriod +
            "&quarter_id=" +
            this.selectedQuarter +
            "&grade_id=" +
            this.selectedGrade +
            "&class_id=" +
            this.selectedClass +
            "&class_name=" +
            this.$route.query.class_name;

          this.breadCrumb.push({
            text: this.classTitle,
            disabled: false,
            href: href,
          });

          this.breadCrumb.push({
            text: this.$i18n.t("Subject Mark"),
            disabled: true,
            href: "#",
          });
        });
    },

    print() {
      if (!this.pdfUrl) {
        axios
          .get(
            this.getApiUrl +
              "/educational-history/admin/overview/subject/show/print?academic_period_id=" +
              this.selectedPeriod +
              "&grade_id=" +
              this.selectedGrade +
              "&class_id=" +
              this.selectedClass +
              "&subject_id=" +
              this.selectedSubject +
              "&quarter_id=" +
              this.selectedQuarter,

            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.pdfUrl = response.data.data;
            window.open(this.pdfUrl, "_blank");
          });
      } else {
        window.open(this.pdfUrl, "_blank");
      }
    },
  },
  mounted() {
    this.selectedPeriod = this.$route.query.academic_id;
    this.selectedQuarter = this.$route.query.quarter_id;
    this.selectedGrade = this.$route.query.grade_id;
    this.selectedClass = this.$route.query.class_id;
    this.selectedSubject = this.$route.query.subject_id;
    this.getResults();
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.subjectView {
  .sub-title {
    color: gray;
    margin-top: 12px;
  }
  table {
    th {
      text-align: center !important;

      .details {
        margin: 0 12px;
        display: inline-block;
      }
    }
    td {
      .qualifierMark {
        margin: 0 12px;
      }
    }
  }
}

.passed {
  color: green;
}
.faild {
  color: red;
}
.unAssignedMark {
}
</style>
