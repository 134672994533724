var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subjectView"},[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadCrumb,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('h2',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.subjectName))])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"main-btn mt-4",on:{"click":_vm.print}},[_vm._v(_vm._s(_vm.$t("Print")))])],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{staticClass:"elevation-1 level1",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t("Name"))+" ")]),_c('th',[_vm._v(_vm._s(_vm.subjectName)+" ("+_vm._s(_vm.headers.subject_mark)+")")]),_c('th',[_c('span',{staticClass:"details"},[_vm._v(" "+_vm._s(_vm.$t("Details"))+" ")]),_vm._v(" ( "),_vm._l((_vm.headers.qualifiers),function(qualifier,index){return _c('span',{key:index,staticClass:"qualifier-title"},[(qualifier.hasExams)?_c('router-link',{attrs:{"to":`/educationalhistory/qualifiersmarks?period_id=${_vm.selectedPeriod}&quarter_id=${_vm.selectedQuarter}&grade_id=${_vm.selectedGrade}&class_id=${_vm.selectedClass}&subject_id=${_vm.selectedSubject}&qualifier_id=${qualifier.subjects_qualifier_id}&subjectTitle=${_vm.classTitle}&subject_name=${_vm.subjectName}&class_name=${_vm.className}&qualifier_title=${qualifier.title}`}},[_vm._v(_vm._s(qualifier.title))]):_c('span',{staticClass:"mb-0"},[_vm._v(_vm._s(qualifier.title))]),(index < _vm.headers.qualifiers.length - 1)?_c('span',{staticClass:"space"},[_vm._v(" - ")]):_vm._e()],1)}),_vm._v(" ) ")],2)])]),_c('tbody',_vm._l((_vm.students),function(student,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(student.student_name))]),_c('td',[(
                    _vm.headers.passing_mark &&
                      Number(_vm.headers.passing_mark) >
                        Number(student.student_mark)
                  )?_c('p',{staticClass:"faild"},[_vm._v(" "+_vm._s(student.mark_display)+" ")]):_vm._e(),(
                    _vm.headers.passing_mark &&
                      Number(_vm.headers.passing_mark) <=
                        Number(student.student_mark)
                  )?_c('p',{staticClass:"passed"},[_vm._v(" "+_vm._s(student.mark_display)+" ")]):_vm._e(),(!_vm.headers.passing_mark)?_c('p',{staticClass:"unAssigned"},[_vm._v(" "+_vm._s(student.mark_display)+" ")]):_vm._e()]),(student.qualifiers.length > 0)?_c('td',_vm._l((student.qualifiers),function(qualifier,index){return _c('span',{key:index,staticClass:"qualifierMark"},[_c('span',[_vm._v(" "+_vm._s(qualifier.qualifier_title)+" ")]),_c('span',[_vm._v(_vm._s(qualifier.mark_display))])])}),0):_c('td',[_vm._v("N/A")])])}),0)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }